@font-face {
  font-family: 'baloo-bold';
  src: url('./assets/fonts/Baloo2-Bold.ttf');
}


@font-face {
  font-family: 'baloo';
  src: url('./assets/fonts/Baloo2-Regular.ttf');
}

@font-face {
  font-family: 'baloo-medium';
  src: url('./assets/fonts/Baloo2-Medium.ttf');
}

@font-face {
  font-family: 'baloo-semibold';
  src: url('./assets/fonts/Baloo2-SemiBold.ttf');
}

@font-face {
  font-family: 'baloo-variable';
  src: url('./assets/fonts/Baloo2-VariableFont_wght.ttf');
}

body{
  margin: 0px;
  padding: 0px;
  overflow: hidden;
  font-family: 'baloo-variable';
  background-color: #E4DAD0;
}

.app-container {
  width: 100%;
  height: 100dvh;
}

.icon{
 font-size: 60px;
  margin: auto;
  margin-bottom: 5px;
  margin-top: 5px;
  color:#353737;
}

.footer{
  display: flex;
  justify-content: space-between;

  .col{
      display: flex;
      flex-direction: row;
      .link{
          display: flex;
          flex-direction: column;
          justify-content: center;
          text-align: center;
          padding: 10px;
          min-width: 100px;
          text-decoration: none;
          color:inherit;
          .icon-footer{
              
          }
      }
  }
}

.icon-close{
  cursor: pointer;
}