
#splash-container-background{
    background-image: url("./../assets/bg.jpg");
    background-size: cover;
    background-position: center center;
   
}

#splash-container{

    position: absolute;
    top: 0px;
    left:0px;
    background-color: rgba($color: white, $alpha: 0.65);

    display: flex;
    align-items: center;
    justify-content: center;

    .splash-text{
        color: #353737;
        width: 570px;
        font-family: 'baloo-variable';
        padding-bottom: 100px;
        .titles{
            font-size: 64px;

            .title-one{
                text-align: left;
                font-weight: 500;
                height: 70px;
                padding-left: 30px;
            }
            .title-two{
                text-align: right;
                font-weight: 600;
                padding-right: 30px;
            }
        }

        .explore-text{
            font-size: 36px;
            font-weight: 500;
        }
    }

}

.loader-container{
    position: absolute;
    width: 100%;
    bottom: 100px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}