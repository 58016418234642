#pbp{
    position: absolute;
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    user-select: none;

    .icon-container{
        height: 45px;
        .icon-arrow{
            font-size: 72px;
            color: #201747;
            
        }
    }
    

    .branding{
        display: flex;
        .poweredby{
            font-size: 20px;
    
           margin-bottom: 10px;
           color: #201747;
           font-weight: 500;
        }
    
        img{
            height: 20px;
            padding-left: 7px;
            margin-top: 7px;
           
        }
    }

    

    padding-bottom: 10px;
}