#video {
    position: absolute;
    width: 100%;
    height: 100dvh;
}

#canvas {
    position: absolute;
    // width: 100%;
    // height: 100dvh;
}

.header-scanner {
    position: absolute;
    width: calc(100% - 40px);
}

.btn {
    position: absolute;
    bottom: 100px;
    left: 50%;
    transform: translate(-50%);
    display: none;
    font-size: 32px;
}

.footer-scanner {
    position: absolute;
    bottom: 0px;
}

.scanner-rect {
    width: 550px;
    height: 300px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .rect-container {
        width: inherit;
        height: inherit;
        position: relative;
        .rect {
            width: 40px;
            height: 40px;
            border-color: #353737;
            border-width: 1px;
            position: absolute;
        }

        .rect-one {
            border-top: solid;
            border-left: solid;
            top:0px;
            left: 0px;
        }

        .rect-two {
            border-top: solid;
            border-right: solid;
            top:0px;
            right: 0px;
        }

        .rect-three {
            border-right: solid;
            border-bottom: solid;
            right: 0px;
            bottom: 0px;
        }

        .rect-four {
            border-left: solid;
            border-bottom: solid;
            left: 0px;
            bottom: 0px;
        }
    }

.scan-text{
    font-size: 28px;
    text-align: center;
    padding-top: 20px;
}

}