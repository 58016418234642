.header{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 10px;
    .row{
        display: flex;
        .app-title{
            font-size: 40px;
            
        }

        .icon-header{
            height: 40px;
            transform: translateY(10px);
            cursor: pointer;
        }

        
    }

    
    .header-title{
        font-size: 36px;
    }
}

.icon-close{
    position: absolute;
    top: 30px;
    right:10px;
    height: 40px;
}