.app-container{
    .header-tour{
        position: absolute;
    width: 100%;
    }

    .footer-tour{
        position: absolute;
    bottom: 0px;
    }
}

canvas{
    user-select: none;
}

#poi-container{
    position: absolute;
    width: 100%;
    height: 100dvh;
    top: 0;
    left: 0;
    overflow: hidden;
    pointer-events: none;

    .pois{
        position: relative;
        width: inherit;
        height: inherit;

        .poi{
            position: absolute;
            padding: 5px;
            background-color: rgba(255, 255, 255, 0.5);
            border-radius: 32px;
            cursor: pointer;
            transform: translate(-33px, -33px);
            pointer-events: all;
            user-select: none;

            .poi-icon{
                width: 46px;
                height: 46px;
                color:#353737;
            }

            .h-title{
                white-space: nowrap;
                font-size: 30px;
                padding-right: 10px;
                padding-left: 5px;
                padding-top:0px;
                user-select: none;
                color:#353737;
            }
        
        }
    }
}

.popup-contaner{
    position: absolute;
    width: 100%;
    height: 100dvh;
    top: 0px;
    left: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
   

    &.open-pop{
        -webkit-animation: fadeout 0.2s linear forwards;
        animation: fadeout 0.2s linear forwards;
        opacity: 0;
    }

    &.close-pop{
        -webkit-animation: fadein 0.2s linear forwards;
        animation: fadein 0.2s linear forwards; 
        opacity: 1;
    }

    .popup-background{
        width: inherit;
        height: inherit;
        position: absolute;
        top:0px;
        left:0px;
        background-color: rgba(255, 255, 255, 0.7);
        backdrop-filter:blur(2px);
    }

    .popup{
        padding: 10px;
        background-color: white;
        width: 33%;
        max-height: 80%;
        z-index: 1;
        .popup-header{
            display: flex;
            justify-content: space-between;
            .popup-title{
                font-size: 32px;
                width: 100%;
            }
        }

        .popup-asset-gallery{
            img{
                width: 100%;
            }
        }

        .popup-object-container{
            width: 100%;
            height: 50dvh;
        }

        .popup-description{
            text-align: justify;
            margin-top:10px;
        }
    }
}


@-webkit-keyframes fadeout {
    100% { opacity: 1; }
  }
  
  @keyframes fadeout {
    100% { opacity: 1; }
  }


  @-webkit-keyframes fadein {
    100% { opacity: 0; }
  }
  
  @keyframes fadein {
    100% { opacity: 0; }
  }

  /* .popup-object-container{
    position: absolute;
    width: 50%;
    height: 70dvh;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
  } */

  .year-slider{
    position: absolute;
    right: 100px;
    top: 50%;
    transform: translateY(-50);
  }

  .tour-guide-pop{
    -webkit-animation: popguidfadein 7s linear forwards;
        animation: popguidfadein 7s linear forwards; 
    position: absolute;
    top: 50%;
    left: 50%;
    width: 500px;
    height: 400px;
    background-image: url('./../assets/tour-pop-icon.svg');
    transform: translate(-50%, -50%);
    background-size: contain;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;

    .tour-guide-text{
        font-size: 24px;
        width: 330px;
        text-align: center;
        user-select: none;
    }
  }

  @keyframes popguidfadein {
    0% { opacity: 1};
    90% {opacity: 1;};
    95% {opacity: 0;};
    100% {opacity: 0;};
  }

  .MuiSlider-thumb{
    width: 35px !important;
    height: 35px !important;
    
  }

  .MuiSlider-root{
    color:#353737 !important;
  }

  .MuiSlider-markLabel{
    left: unset !important;
    right: 40px;
    font-size: 24px !important;
    font-family: 'baloo-variable' !important;
  }

  .Mui-active{
    box-shadow: unset !important;
  }
  
  .Mui-focusVisible{
    box-shadow: unset !important;
  }

  .MuiSlider-track{
    border: unset;
  }