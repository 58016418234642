#HomeContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: absolute;
    top: 0px;
    left:0px;
    background-color: rgba($color: white, $alpha: 0.65);

    .link-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .hunt-container{
            font-size: 24px;
            font-weight: 500;
            color:#353737;
            padding-left: 60px;
            padding-top: 20px;

            .icon-hunt{
                height: 40px;
                transform: translate(-30px, 10px);
            }
        }
       
        .links {
            width: auto;
            display: grid;
            grid-template-columns: auto auto auto auto;
            .link {
                display: flex;
                flex-direction: column;
                justify-content: center;
                text-align: center;
                padding: 20px 10px;
                min-width: 90px;
                text-decoration: none;
            color:#353737;
            font-size: 20px;
            font-weight: 500;
                .icon-home{
                    font-size: 72px;
                    height: 60px;
                    
                    g{
                        path{
                            stroke: #353737 !important;
                        }
                    }

                }

                .icon-fill{
                    
                    
                    g{
                        path{
                            fill: #353737 !important;
                        }
                    }

                }
            }

        }
    }
}

.icon-footer{
    height: 40px;
}