.sidebar{
    display: flex;
    flex-direction: column;
    width: 60px;
    height: 100dvh;
    justify-content: center;
    position: absolute;
    top:0px;
    left:0px;
    
    .icon-sidebar{
        width: 40px;
        height: 40px;
        padding: 10px;

        &.fill{
            path{
                fill:#353737 !important;
            }
        }

        &.stroke{
            path{
                stroke:#353737 !important;
            }
        }
    }


}